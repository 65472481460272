/** Bootswatch customizations (LUX) */
h1,
h2,
h3,
h4,
h5 {
  letter-spacing: 0.1rem;
}
.nav-item {
  margin-right: 0.5rem;
}
.table th,
.table td {
  padding: 0.7rem;
}
.badge {
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem 0.1rem 0.5rem;
}
.badge-primary {
  background-color: #cccccc;
}
.dropdown-menu {
  font-size: 10pt;
}

.nav-pills > .nav-item > .nav-link.disabled > a {
  color: #aaaaaa;
}

.btn-lg {
  padding: 1.2rem;
  font-size: 11pt;
}

/* bootstrap.css defines font-weight: bolder, but this font does not support it */
b,
strong {
  font-weight: 900;
  color: black;
  text-shadow: 0 0 1px #777777;
}
.breadcrumb {
  padding-top: 0.8rem;
  background: rgba(0, 0, 0, 0.035);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

a,
a.btn.btn-link {
  text-decoration: underline;
  text-decoration-color: hsla(36, 100%, 50%, 0.15);
  text-decoration-thickness: 3px;
  color: hsl(36, 50%, 20%);
  font-weight: 700;
}
a:hover,
a.btn.btn-link:hover {
  text-decoration: underline;
  text-decoration-color: hsla(36, 100%, 50%);
  text-decoration-thickness: 3px;
}
/* Hide the underline from these links */
.nav-link,
.dropdown-item,
.breadcrumb-item a,
.btn,
.disabled a {
  text-decoration: none;
}

/* needed to override `a` style */
.nav-link {
  font-weight: normal;
}
.navbar-nav .nav-link {
  font-weight: 600;
}

/* Custom styles */

.link-box {
  display: block;
  padding: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 5px solid hsl(36, 10%, 90%);
  background-color: hsl(36, 10%, 97%);
  color: hsl(36, 20%, 30%);
  text-decoration: none;
  font-weight: normal;
  font-size: 10pt;
  margin-bottom: 10px;
}

.link-box:hover {
  text-decoration: none;
  border-color: hsl(36, 100%, 70%);
  color: hsl(36, 100%, 10%);
  background-image: linear-gradient(
    to right,
    hsl(36, 40%, 94%),
    70%,
    hsl(36, 30%, 98%)
  );
}

.link-box-header {
  display: block;
  font-size: 14pt;
  font-weight: 700;
}
