/* Hero heights for different screen widths */
/* @media screen and (max-width: 767px) {
  .hero {
    height: 90vh;
  }
}

@media screen and (min-width: 768px) {
  .hero {
    height: 75vh;
  }
}

@media screen and (min-width: 1280px) {
  .hero {
    height: 65vh;
  }
} */

.hero {
  height: 85vh;
  width: 100%;
  display: table;
}

.hero-text-container {
  width: 100%;
  height: 100%;
  padding-top: 70px;
  display: table-cell;
  vertical-align: middle;
}

.hero-text {
  text-align: center;
  color: white;
  font-size: 18pt;
  width: 100%;
}

.hero-heading {
  font-size: 32pt;
  font-family: 'Helvetica';
  font-weight: 700;
  padding: 20px;
  text-shadow: 0 0 40px #333333;
  line-height: 40pt;
}

.hero-subheading {
  font-size: 24pt;
  text-shadow: 1px 1px 15px black;
}
