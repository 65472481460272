.carousel-control-next,
.carousel-control-prev {
  background: none;
}

.carousel-control-prev:hover {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.25)),
    to(rgba(0, 0, 0, 0.001))
  );
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.001)
  );
}

.carousel-control-next:hover {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.25)),
    to(rgba(0, 0, 0, 0.001))
  );
  background-image: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.001)
  );
}
